import React, { useState } from 'react'
import * as Styled from './styles'
import { useTranslation } from '../../../hooks'
import IconLanguage from '@veneer/core/dist/scripts/icons/icon_language'
import Card from '@veneer/core/dist/scripts/card'
import Select, {
  SelectOption,
  SelectOnChangeType
} from '@veneer/core/dist/scripts/select'
import { getLanguagesFromCountry } from '../../../helpers'
import { localeToStringsJson } from '../../../assets/locale'
import { OptionObject } from '../../../types'
import { LanguageSelectorProps } from '../LanguageSelector'

export const LanguageSelectorCard = ({
  locale,
  onSaveLanguage
}: LanguageSelectorProps) => {
  const { getText } = useTranslation()
  const [language, country] = locale.split('_')
  const languages = getLanguagesFromCountry(localeToStringsJson, country)

  const [selectedLanguage, setSelectedLanguage] = useState([language])

  const handleChange = (language: OptionObject) => {
    setSelectedLanguage([language.value])
  }

  const handleUpdate = () => {
    onSaveLanguage(selectedLanguage[0])
  }

  const shouldDisableUpdateButton = selectedLanguage[0] === language

  const languageSelectorContent = () => {
    return (
      <Styled.LanguageSelectorContent>
        <IconLanguage size={32} />
        <Styled.LanguageSelectorTitle>
          {getText('language_selector_card.title')}
        </Styled.LanguageSelectorTitle>
        <Styled.LanguageSelectorDescription>
          {getText('language_selector_card.description')}
        </Styled.LanguageSelectorDescription>
        <Styled.LanguageSelectorSelectContainer>
          <Select
            label={getText('country_selector_modal.language.select_label')}
            options={languages as SelectOption<string>[]}
            value={selectedLanguage}
            onChange={handleChange as SelectOnChangeType}
            clearIcon={false}
          />
        </Styled.LanguageSelectorSelectContainer>
        <Styled.LanguageSelectorButton
          disabled={shouldDisableUpdateButton}
          onClick={handleUpdate}
        >
          {getText('shared.update_button')}
        </Styled.LanguageSelectorButton>
      </Styled.LanguageSelectorContent>
    )
  }

  return (
    <Card
      background={'filled'}
      border={'dropShadow'}
      content={languageSelectorContent()}
    />
  )
}
