import { ReactElement, RefObject } from 'react'
import styled, { css } from 'styled-components'
import primitives from '@veneer/primitives'
import Button from '@veneer/core/dist/scripts/button'

export const CountrySelectorGapHandlerContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }
`

export const CountrySelectorGapHandlerTitle = styled.h3`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: ${primitives.color.gray12};
    margin: 24px 0 20px;
  }
`

const CountrySelectorGapHandlerDescription = styled.p`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${primitives.color.gray12};
    margin-bottom: 40px;
  }
`

export const CountrySelectorGapHandlerFirstDescription = styled(
  CountrySelectorGapHandlerDescription
)`
  && {
    margin-bottom: 48px;
  }
`

export const constCountrySelectorGapHandlerSecondDescription = styled(
  CountrySelectorGapHandlerDescription
)``

export const ButtonsContainer = styled.div<{
  $shouldWrapButtons: boolean
  ref: RefObject<HTMLDivElement>
  children: ReactElement[]
}>`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    ${({ $shouldWrapButtons }) =>
      $shouldWrapButtons &&
      css`
        flex-direction: column-reverse;
      `}
  }
`

const StyledButton = styled(Button)`
  && {
    padding: 14px 24px;

    span {
      font-family: 'Forma DJR UI', serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      white-space: normal;
    }
  }
`

export const LeftButton = styled(StyledButton)<{ $shouldWrapButtons: boolean }>`
  && {
    ${({ $shouldWrapButtons }) =>
      $shouldWrapButtons &&
      css`
        width: 100%;
      `}
  }
`

export const RightButton = styled(StyledButton)<{
  $shouldWrapButtons: boolean
}>`
  && {
    margin-left: 16px;

    ${({ $shouldWrapButtons }) =>
      $shouldWrapButtons &&
      css`
        width: 100%;
        margin-left: 0;
        margin-bottom: 16px;
      `}
  }
`
