import { OptionObject } from '../types'

export function supportedCountries(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: Record<string, any>,
  isSelectOptions?: boolean
): OptionObject[] | string[] {
  const countries: string[] = []
  const countriesAsOption: OptionObject[] = []

  Object.keys(locales).forEach(function (key) {
    const countryParts = key.split('_')
    const country = countryParts[1]

    if (!countries.includes(country) && !isSelectOptions) {
      countries.push(country)
    } else if (
      isSelectOptions &&
      !countriesAsOption.some((option) => option.value === country)
    ) {
      countriesAsOption.push({
        value: country,
        label: locales[key]['locales'][key].country
      })
    }
  })

  return isSelectOptions ? countriesAsOption : countries
}

export function supportedLanguages(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: Record<string, any>
): Record<string, OptionObject[]> {
  const languagesAsOption: Record<string, OptionObject[]> = {}

  Object.keys(locales).forEach(function (key) {
    const [language, country] = key.split('_')

    if (!languagesAsOption[country]) {
      languagesAsOption[country] = [
        {
          value: language,
          label: locales[key]['locales'][key].language
        }
      ]
    } else if (
      !languagesAsOption[country].some((option) => option.value === language)
    ) {
      languagesAsOption[country].push({
        value: language,
        label: locales[key]['locales'][key].language
      })
    }
  })

  return languagesAsOption
}

export const getLanguagesFromCountry = (
  locales: Record<string, any>,
  country: string
): OptionObject[] => {
  const languagesAsOption: OptionObject[] = []

  Object.keys(locales).forEach(function (key) {
    const [language, countryKey] = key.split('_')

    if (countryKey === country) {
      languagesAsOption.push({
        value: language,
        label: locales[key]['locales'][key].language
      })
    }
  })

  return languagesAsOption
}
