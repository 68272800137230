import React, { useState } from 'react'
import { TranslationProvider } from '../../contexts/TranslationProvider'
import { CountrySelectorButton } from './CountrySelectorButton/CountrySelectorButton'
import { CountrySelectorModal } from './CountrySelectorModal/CountrySelectorModal'
import { GapHandlerModal } from '../GapHandler/GapHandlerModal/GapHandlerModal'

export interface CountrySelectorProps {
  /* showCountrySelector: Opens or close the Country Selector modal */
  showCountrySelector?: boolean

  /* setShowCountrySelector: Callback to closes the Country Selector modal */
  setShowCountrySelector?: (showCountrySelector: boolean) => void

  /* showFlagButton: Show or not the button with the country flag and code
   * If the value is false then the showCountrySelector
   * and setShowCountrySelector properties should be passed
   * */
  showFlagButton?: boolean

  /* accountLocale: The locale registered on the account
   * Should be like 'en_US', 'fr_CA'
   * */
  accountLocale: string

  /* onSave: Callback to save the changes made. Will send as a parameter
   * the locale string like 'en_US', 'en_CA'
   * */
  onSave: (locale: string) => void

  /* isCountryChangeAllowed: Allow the user to change the country
   * If the value is false and the country is changed,
   * when the save button is clicked the Gap Handler modal will be shown.
   * */
  isCountryChangeAllowed?: boolean

  /* If the isCountryChangeAllowed is FALSE, then provide the following props
   * Default value: false
   * */

  /* onCreateNewAccount: Function to redirect to create a new account
   * Will be send the locale code as parameter.
   * i.e. 'en_US', 'fr_CA'
   * */
  onCreateNewAccount?: (locale: string) => void

  /* onContinueEnroll: Function to continue the enrollment */
  onContinueEnrollment?: () => void
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const [showGapHandlerModal, setShowGapHandlerModal] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(props.accountLocale)
  const [language, country] = props.accountLocale.split('_')
  const [showInternalCountrySelector, setShowInternalCountrySelector] =
    useState(false)

  const onSave = (locale: string) => {
    const selectedCountry = locale.split('_')[1]
    if (!props.isCountryChangeAllowed && selectedCountry !== country) {
      setSelectedLocale(locale)
      setShowGapHandlerModal(true)
    } else {
      props.onSave(locale)
    }

    if (props.showFlagButton) {
      setShowInternalCountrySelector(false)
    } else {
      props.setShowCountrySelector(false)
    }
  }

  const onContinueEnrollment = () => {
    if (!props.isCountryChangeAllowed) {
      setShowGapHandlerModal(false)
    }

    setSelectedLocale(props.accountLocale)
    props.onContinueEnrollment()
  }

  const countrySelectorProps = {
    showCountrySelector: props.showFlagButton
      ? showInternalCountrySelector
      : props.showCountrySelector,
    setShowCountrySelector: props.showFlagButton
      ? setShowInternalCountrySelector
      : props.setShowCountrySelector,
    onSave,
    accountLocale: props.accountLocale
  }

  const gapHandlerProps = {
    showGapHandlerModal,
    setShowGapHandlerModal,
    onContinueEnrollment: onContinueEnrollment,
    onCreateNewAccount: props.onCreateNewAccount,
    selectedLocale,
    accountLocale: props.accountLocale
  }

  return (
    <TranslationProvider language={language} country={country}>
      {props.showFlagButton && (
        <CountrySelectorButton
          accountLocale={props.accountLocale}
          setShowCountrySelector={setShowInternalCountrySelector}
        />
      )}
      <CountrySelectorModal {...countrySelectorProps} />
      <GapHandlerModal {...gapHandlerProps} />
    </TranslationProvider>
  )
}

CountrySelector.defaultProps = {
  showCountrySelector: false,
  setShowCountrySelector: () => null,
  showFlagButton: false,
  accountLocale: 'en_US',
  onSave: () => null,
  isCountryChangeAllowed: false,
  onCreateNewAccount: () => null,
  onContinueEnrollment: () => null
}
