import { createContext } from 'react'

export interface TranslationContextProps {
  getText: (
    translationPath: string,
    options?: Record<string, unknown>
  ) => string | null
  changeTranslation: (locale: string) => void
}

export const TranslationContext = createContext<TranslationContextProps | null>(
  { getText: () => '', changeTranslation: () => null }
)
