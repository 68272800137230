import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Styled from './styles'
import primitives from '@veneer/primitives'
import Modal from '@veneer/core/dist/scripts/modal'
import GlobeIcon from '@veneer/core/dist/scripts/icons/icon_globe'
import LanguageIcon from '@veneer/core/dist/scripts/icons/icon_language'
import Select, {
  SelectOnChangeType,
  SelectOption,
  SelectValue
} from '@veneer/core/dist/scripts/select'
import * as Flags from '@veneer/flags/dist'
import { OptionObject, SelectedOptionsObject } from '../../../types'
import { useTranslation } from '../../../hooks'
import { checkAndSetButtonsWrap } from '../../../helpers/layoutHelpers'
import { CountrySelectorProps } from '../index'
import { supportedCountries, supportedLanguages } from '../../../helpers'
import { localeToStringsJson } from '../../../assets/locale'

export const CountrySelectorModal = ({
  showCountrySelector,
  setShowCountrySelector,
  accountLocale,
  onSave
}: CountrySelectorProps) => {
  const { getText, changeTranslation } = useTranslation()
  const [language, country] = accountLocale.split('_')
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsObject>(
    { country, language }
  )

  const countryOptions = useMemo(
    () => supportedCountries(localeToStringsJson, true),
    []
  )
  const languageOptions = useMemo(
    () => supportedLanguages(localeToStringsJson),
    []
  )

  const [languageList, setLanguageList] = React.useState<OptionObject[]>(
    languageOptions[selectedOptions?.country]
  )
  const [countryList, setCountryList] = React.useState<OptionObject[]>([])

  const [languageValue, setLanguageValue] = React.useState<string[]>([
    languageOptions[selectedOptions?.country].find((lang: OptionObject) => {
      return lang.value === selectedOptions.language
    }).value
  ])
  const [countryValue, setCountryValue] = React.useState<React.ReactNode>([
    selectedOptions?.country
  ])
  const [shouldWrapButtons, setShouldWrapButtons] = useState(false)
  const leftButtonRef = useRef(null)
  const rightButtonRef = useRef(null)
  const buttonsContainerRef = useRef(null)

  const checkAndSetWrap = useCallback(() => {
    const shouldWrap = checkAndSetButtonsWrap(
      rightButtonRef,
      leftButtonRef,
      buttonsContainerRef
    )
    setShouldWrapButtons(shouldWrap)
  }, [])

  useEffect(() => {
    checkAndSetWrap()

    const handleResize = () => {
      checkAndSetWrap()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [showCountrySelector, checkAndSetWrap, getText])

  useEffect(() => {
    changeTranslation(`${selectedOptions.language}_${selectedOptions.country}`)
  }, [changeTranslation, selectedOptions, countryValue, languageValue])

  useEffect(() => {
    const countryFlag = (country: string) => {
      const countryCode = country.toUpperCase()
      const Flag = Flags[`Flag${countryCode}`]

      return <Flag size={28} />
    }

    const countriesWithFlags = (countryOptions as OptionObject[])
      .sort((a: OptionObject, b: OptionObject) => {
        return a.label.localeCompare(b.label)
      })
      .map((country: OptionObject) => {
        return {
          value: country.value,
          label: country.label,
          icon: countryFlag(country.value)
        }
      })
    setCountryList(countriesWithFlags)
  }, [countryOptions])

  const handleCountryChange = (event: OptionObject) => {
    const countryCode = event.value.toUpperCase()

    const countryLanguages = languageOptions[countryCode]

    let optionsSelected = { ...selectedOptions, country: countryCode }
    const languageIsOnSelectedCountry =
      languageValue[0] &&
      countryLanguages.some(
        (language: { value: string }) =>
          language.value.toLowerCase() === languageValue[0].toLowerCase()
      )
    if (!languageIsOnSelectedCountry) {
      setLanguageValue([countryLanguages[0].value])
      optionsSelected = {
        ...optionsSelected,
        language: countryLanguages[0].value
      }
    }

    setCountryValue([countryCode])
    setLanguageList(countryLanguages)
    setSelectedOptions(optionsSelected)
  }

  const handleLanguageChange = (event: OptionObject) => {
    setLanguageValue([event.value])
    setSelectedOptions({ ...selectedOptions, language: event.value })
    changeTranslation(`${event.value}_${selectedOptions.country}`)
  }

  const resetSelectedOptions = () => {
    setSelectedOptions({ country, language })
    setLanguageList(languageOptions[country])
    setCountryValue([country])
    setLanguageValue([language])
  }

  const handleOnSave = () => {
    resetSelectedOptions()
    onSave(`${selectedOptions.language}_${selectedOptions.country}`)
  }

  const handleOnClose = () => {
    resetSelectedOptions()
    setShowCountrySelector(false)
  }

  return (
    <Modal
      show={showCountrySelector}
      onClose={handleOnClose}
      maxWidth={480}
      closeButton
      closeOnBlur={false}
    >
      <Styled.CountrySelectorContainer>
        <GlobeIcon color={primitives.color.gray12} size={48} />
        <Styled.CountrySelectorLabel>
          {getText('country_selector_modal.country.title')}
        </Styled.CountrySelectorLabel>
        <Styled.CountrySelectorSelectContainer>
          <Select
            label={getText('country_selector_modal.country.select_label')}
            onChange={handleCountryChange as SelectOnChangeType}
            options={countryList as SelectOption<string>[]}
            value={countryValue as SelectValue[]}
            clearIcon={false}
          />
        </Styled.CountrySelectorSelectContainer>
        <LanguageIcon color={primitives.color.gray12} size={48} />
        <Styled.LanguageSelectorLabel>
          {getText('country_selector_modal.language.title')}
        </Styled.LanguageSelectorLabel>
        <Styled.LanguageSelectorSelectContainer>
          <Select
            label={getText('country_selector_modal.language.select_label')}
            onChange={handleLanguageChange as SelectOnChangeType}
            options={languageList as SelectOption<string>[]}
            value={languageValue}
            clearIcon={false}
          />
        </Styled.LanguageSelectorSelectContainer>
        <Styled.ButtonsContainer
          ref={buttonsContainerRef}
          $shouldWrapButtons={shouldWrapButtons}
        >
          <Styled.LeftButton
            appearance="secondary"
            ref={leftButtonRef}
            onClick={handleOnClose}
            $shouldWrapButtons={shouldWrapButtons}
          >
            {getText('shared.cancel_button')}
          </Styled.LeftButton>
          <Styled.RightButton
            ref={rightButtonRef}
            onClick={handleOnSave}
            $shouldWrapButtons={shouldWrapButtons}
          >
            {getText('shared.save_button')}
          </Styled.RightButton>
        </Styled.ButtonsContainer>
      </Styled.CountrySelectorContainer>
    </Modal>
  )
}
