import React from 'react'
import * as Flags from '@veneer/flags/dist'
import * as Styled from './styles'

interface CountrySelectorButtonProps {
  accountLocale: string
  setShowCountrySelector: (value: boolean) => void
}

export const CountrySelectorButton = ({
  accountLocale,
  setShowCountrySelector
}: CountrySelectorButtonProps) => {
  const countryCode = accountLocale.split('_')[1].toUpperCase()

  const countryFlag = () => {
    const Flag = Flags[`Flag${countryCode}`]

    return <Flag size={24} />
  }

  const onOpen = () => setShowCountrySelector(true)

  return (
    <>
      <Styled.CountryButton appearance={'ghost'} onClick={onOpen}>
        <Styled.FlagContainer>{countryFlag()}</Styled.FlagContainer>
        <Styled.CountryCode>{countryCode}</Styled.CountryCode>
      </Styled.CountryButton>
    </>
  )
}
