import React, { useCallback, useEffect, useState } from 'react'
import { TranslationContext } from './TranslationContext'
import { Loading } from '../components/Loading/Loading'

interface TranslationProviderProps {
  children: React.ReactNode
  language: string
  country: string
}

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
  language,
  country
}) => {
  const [currentTranslation, setCurrentTranslation] = useState<Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  > | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [locale, setLocale] = useState(
    `${language.toLowerCase()}_${country.toUpperCase()}`
  )

  const loadTranslation = async (locale: string) => {
    try {
      const translation = await import(`../assets/locale/${locale}.json`)
      return translation.default
    } catch (error) {
      console.error(`Failed to load translation: ${locale}`, error)
      return null
    }
  }

  const changeTranslation = useCallback(async (locale: string) => {
    let translation = await loadTranslation(locale)
    setLocale(locale)

    if (!translation) {
      console.error('\nSetting default language to en_US')
      translation = await loadTranslation('en_US')
    }

    if (!translation) {
      console.error('Failed to load default translation')
      return
    }

    setCurrentTranslation(translation)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const locale = `${language.toLowerCase()}_${country.toUpperCase()}`
    changeTranslation(locale)
  }, [changeTranslation, country, language])

  const getText = useCallback(
    (translationPath: string, options?: Record<string, unknown>): string => {
      if (!currentTranslation) {
        return null
      }

      const keys = translationPath.split('.')
      let translation = currentTranslation
      for (const key of keys) {
        if (translation[key]) {
          translation = translation[key]
        } else {
          return `${locale}.${translationPath}`
        }
      }
      if (!translation && (translation as unknown as string) !== '') {
        return `${locale}.${translationPath}`
      }
      if (translation.includes('{{')) {
        for (const key in options) {
          const regex = new RegExp(`{{${key}}}`, 'g')
          translation = translation.replace(regex, options[key] as string)
        }
      }
      return translation as unknown as string
    },
    [currentTranslation, locale]
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <TranslationContext.Provider value={{ getText, changeTranslation }}>
      {children}
    </TranslationContext.Provider>
  )
}
